import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import {Input, message} from 'antd';
import imgOrderSearchIcon from '../../images/order-search.png';
import Button from '../../Components/Button';
import {BREAK_POINTS} from '../../Domain/Constants';
import OrderInfo from '../../Components/OrderInfo';
import CartItem from '../../Components/CartItem';

function OrderPage(props) {
  const [order, setOrder] = React.useState(null);
  const params = React.useMemo(() => qs.parse(props.location.search), [
    props.location.search,
  ]);
  const [actions] = useOutlet('actions');
  const [values, setValues] = React.useState({
    orderId: '',
    code: '',
  });

  const getOrder = React.useCallback(async () => {
    actions.setLoading(true);
    try {
      let _order = await actions.getOrder(values.orderId, values.code);
      setOrder(_order);
    } catch (err) {
      message.error('無法取得訂單資訊，請確認訂單號碼及驗證碼是否正確');
    }
    actions.setLoading(false);
  }, [values.orderId, values.code, actions]);

  React.useEffect(() => {
    let _nextValues = {
      orderId: '',
      code: '',
    };

    if (params) {
      if (params.id) {
        _nextValues.orderId = params.id;
      }
      if (params.validation_code) {
        _nextValues.code = params.validation_code;
      }
      setValues(_nextValues);
    }
  }, [params]);

  if (order) {
    return (
      <Wrapper>
        <div className="content" style={{paddingBottom: 0}}>
          <div
            style={{
              padding: '15px 20px',
              border: '1px solid #d6d6d6',
              margin: '10px auto',
              borderRadius: 10,
            }}>
            {order.items.map((cartItem, idx) => (
              <CartItem item={cartItem} itemIdx={idx} key={idx} disabled />
            ))}
            <Section>
              <div className="separate" />
              <div className="field">
                <div className="name">小計</div>
                <div className="value">$ {order.subtotal}</div>
              </div>
              {order.extra_items.map((e, i) => (
                <div className="field" key={i}>
                  <div className="name">{e.name}</div>
                  <div className="value">$ {e.amount}</div>
                </div>
              ))}
              <div className="field total">
                <div className="name">總金額</div>
                <div className="value">$ {order.total}</div>
              </div>
            </Section>
          </div>
        </div>
        <OrderInfo
          order={order}
          footerEle={
            <Button
              type={'secondary'}
              style={{marginTop: 20}}
              onClick={() => actions.navigate('/')}>
              回首頁
            </Button>
          }
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="header">
          <div className="circle">
            <img src={imgOrderSearchIcon} />
          </div>
          <h2>訂單查詢</h2>
          <p>
            請輸入您的訂單編號及認證號碼以查詢您的訂單
            <br />
            若您並未收到訂單成立通知信，請檢查您的垃圾信件夾，或者提供您的手機號碼協助客服查詢您的訂單！
          </p>
        </div>
        <div className="form">
          <h2>訂單查詢</h2>
          <div className="form-content">
            <InputField style={{marginBottom: 15}}>
              <label>訂單編號</label>
              <Input
                placeholder={'請輸入您的訂單編號'}
                value={values.orderId}
                onChange={(e) =>
                  setValues({...values, orderId: e.target.value})
                }
              />
            </InputField>
            <InputField>
              <label>認證號碼</label>
              <Input
                placeholder={'請輸入您的認證號碼'}
                value={values.code}
                onChange={(e) => setValues({...values, code: e.target.value})}
              />
            </InputField>
          </div>
          <div className="footer">
            <Button
              disabled={!values.orderId || !values.code}
              type={'secondary'}
              style={{marginTop: 20}}
              onClick={getOrder}>
              訂單查詢
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const InputField = styled.div`
  & > label {
    margin-bottom: 3px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
  }

  & input[disabled] {
    background-color: transparent;
    color: #000;
  }
`;

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  & .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    padding-top: 0;
    padding-bottom: 80px;

    & > .header {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > .circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #846e4f;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        & img {
          width: 96px;
          height: 96px;
        }
      }

      & > h2 {
        margin-bottom: 20px;
        font-size: 20px;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
      }

      & > p {
        margin-bottom: 34px;
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        line-height: 25px;
      }
    }

    & > .form {
      padding: 30px 50px;
      border: 1px solid #dddddd;
      border-radius: 10px;

      & > h2 {
        font-size: 20px;
        letter-spacing: 0;
        padding-bottom: 10px;
      }

      & > .form-content {
        border-top: 1px solid #846e4f;
        border-bottom: 1px solid #846e4f;

        padding-bottom: 27px;
        padding-top: 15px;

        & > .col {
          margin-left: 35px;
          flex-basis: calc((100% - 70px) / 3);
          @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
            flex-basis: 100%;
            margin-left: 0px;
            margin-bottom: 15px;
          }
        }
      }
      & > .footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

const Section = styled.section`
  & > .separate {
    height: 1px;
    background-color: #846e4f;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  & > .field {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    & > .name {
      font-size: 14px;
    }

    & > .value {
      font-size: 20px;
      color: #846e4f;
      min-width: 150px;
      text-align: right;
    }
  }

  & > .field.total {
    background-color: #1e2f29;
    color: #fff;

    & > .value {
      color: #fff;
      font-size: 30px;
    }
  }
`;

export default OrderPage;
